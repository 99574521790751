import { defineStore } from 'pinia'


export const useMainStore = defineStore('main', {
  state: () => ({
    initialHasLoaded: false,
    footerVisible: false,
    loadCompleted: false,
    videoPlaying: false,
  }),
  actions: {
    initialLoad() {
      this.initialHasLoaded = true
    },
    showFooter() {
      this.footerVisible = true
    },
    completeLoad() {
      this.loadCompleted = true
    },
    toggleVideoPlaying(payload) {
      this.videoPlaying = payload
    },
  },
})

// export const actions = {
//   increment(state) {
//     state.counter++
//   },
// }

// v-if="!$store.state.initialHasLoaded"
