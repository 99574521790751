import imageUrlBuilder from '@sanity/image-url'

export default defineNuxtPlugin(() => {

  const builder = imageUrlBuilder(useSanity().config)
  function urlFor(source) {
    return builder.image(source).auto('format')
  }
  // You can alternatively use this format, which comes with automatic type support
  return {
    provide: {
      urlFor: urlFor
    }
  }
})