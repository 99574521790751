import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as _91slug_93PJf01PoLt8Meta } from "/opt/build/repo/pages/directors/[slug].vue?macro=true";
import { default as index2fRlL88rHkMeta } from "/opt/build/repo/pages/directors/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as infog4VNBfc6QTMeta } from "/opt/build/repo/pages/info.vue?macro=true";
import { default as _91slug_93BprztT1NU8Meta } from "/opt/build/repo/pages/video-index/[slug].vue?macro=true";
import { default as honda_45the_45other_45sideyuc8BGeInCMeta } from "/opt/build/repo/pages/video-index/honda-the-other-side.vue?macro=true";
import { default as indexgAHTPiDWJsMeta } from "/opt/build/repo/pages/video-index/index.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/build/repo/pages/contact.vue")
  },
  {
    name: "directors-slug",
    path: "/directors/:slug()",
    meta: _91slug_93PJf01PoLt8Meta || {},
    component: () => import("/opt/build/repo/pages/directors/[slug].vue")
  },
  {
    name: "directors",
    path: "/directors",
    component: () => import("/opt/build/repo/pages/directors/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/opt/build/repo/pages/info.vue")
  },
  {
    name: "video-index-slug",
    path: "/video-index/:slug()",
    component: () => import("/opt/build/repo/pages/video-index/[slug].vue")
  },
  {
    name: "video-index-honda-the-other-side",
    path: "/video-index/honda-the-other-side",
    component: () => import("/opt/build/repo/pages/video-index/honda-the-other-side.vue")
  },
  {
    name: "video-index",
    path: "/video-index",
    component: () => import("/opt/build/repo/pages/video-index/index.vue")
  }
]